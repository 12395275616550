import { useState, useEffect, useRef, useCallback } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout, { HFlex, VFlex } from './Layout.jsx';
import { HashLink as RouterLink } from 'react-router-hash-link';
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.esm.jsx';

// const Five = ({ style = {}, ...props }) => {
//   return <div {...props} style={{ ...style, position: 'absolute', top:0, left: 0, right: 0, height: '1000vh'}}>
//     <ScrollyVideo
//       src={'/LST_Map4-website_2-bv1M.webm'}
//       debug={true}
//       full={true}
//       cover={false}
//       transitionSpeed={1}
//     />
//   </div>
// }

function Two() {
  return <></>;
}

function Stack({ elements, active }) {
  const renderElements = elements.map((elem, i) => {
    const style = active === i ? {} : { display: 'none' };
    return elem({ style, key: i });
  });
  return <>{renderElements}</>;
}

function Box({ children, style }) {
  return <VFlex style={{background: 'red', ...style}}>{children}</VFlex>;
}

function noop() { }

String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

function One() {
  const [active, setActive] = useState(0);

  const states = 5;
  const antiaction = () => setActive(a => (a+states-1)%states);
  const action = () => setActive(a => (a+1)%states);

  const One = ({ style = {}, ...props }) => {
    const videoRef = useRef();

    useEffect(() => {
      if (style?.display === 'none') {
        videoRef.current.currentTime = 0;
        videoRef?.current.pause();
      } else {
        videoRef?.current.play();
      }
    }, [style?.display]);

    style.position = 'relative';

    return <div {...props} style={style}>
      <video
        ref={videoRef}
        width={"100%"}
        height={"100%"}
        autoPlay
        src={'/off.webm'}
        preload={'auto'}
        loop
      >
      </video>
      <div onClick={action} className="switch-hitbox">
      </div>
    </div>;
  }

  const Two = ({ style = {}, ...props }) => {
    style.position = 'relative';

    const initState = {
      pos: 0,
      typed: '__________',
      proceed: false,
    };
    const [dispState, setDispState] = useState(initState);

    function resetState() {
      setDispState(initState);
    }

    useEffect(() => {
      resetState();
    }, []);

    const key = useCallback(({ key }) => {
      if (key.length > 1 && key !== "Backspace")
        return
      setDispState(({pos, typed, proceed}) => {
        if (key === "Backspace") {
          if (pos === 0)
            return initState;
          pos -= 1;
          typed = typed.replaceAt(pos, '_');
        } else {
          typed = typed.replaceAt(pos, key);
          pos += 1;
        }
        if (pos === 10) {
          if (typed === "AAAAAAAAAA") {
            proceed = true;
          } else {
            return initState;
          }
        }
        return { pos, proceed, typed }
      });
    }, []);

    const screenRef = useRef();

    useEffect(() => {
      if (style?.display === 'none' || dispState.proceed) {
        window.removeEventListener('keydown', key);
        if (dispState.proceed) {
          screenRef?.current?.classList.add('fadeout');
          setTimeout(() => {
            action();
            resetState();
            setTimeout(() => screenRef?.current?.classList.remove('fadeout'), 100);
          }, 2000);
        }
      } else {
        window.addEventListener('keydown', key);
      }
    }, [style?.display, dispState.proceed]);


    return <div {...props} style={style}>
      <img style={{width: '100%', height: '100%'}} src="/Standby full.png" />
      <div onClick={antiaction} className="switch-hitbox"></div>
      <div className="screen" ref={screenRef}>
        {dispState.typed}
        { dispState.proceed ? <><br/>SUCCESS</> : null }
      </div>
    </div>
  };

  const Three = ({ style = {}, ...props }) => {
    const videoRef = useRef();

    useEffect(() => {
      if (style?.display === 'none') {
        videoRef.current.currentTime = 0;
        videoRef?.current.pause();
      } else {
        videoRef?.current.play();
      }
    }, [style?.display]);

    return <div {...props} style={style}>
      <video
        ref={videoRef}
        width={"100%"}
        height={"100%"}
        src={'/on.webm'}
        preload={'auto'}
        onEnded={action}
      >
      </video>
    </div>
  }

  const Four = ({ style = {}, ...props }) => {
    const videoRef = useRef();

    useEffect(() => {
      if (style?.display === 'none') {
        videoRef.current.currentTime = 0;
        videoRef?.current.pause();
      } else {
        videoRef?.current.play();
      }
    }, [style?.display]);

    return <div {...props} style={style}>
      <video
        width={"100%"}
        height={"100%"}
        ref={videoRef}
        src={'/fa4.webm'}
        preload={'auto'}
        onEnded={action}
      >
      </video>
    </div>
  }

  const Five = ({ style = {}, ...props }) => {
    style.position = 'relative';
    style.overflow = 'hidden';

    const videoRef = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      if (style?.display === 'none') {
        videoRef.current.currentTime = 0;
        videoRef?.current.pause();
        setVisible(false);
      } else {
        videoRef?.current.play();
      }
    }, [style?.display]);

    return <div {...props} style={style}>
      <video
        width={"100%"}
        height={"100%"}
        ref={videoRef}
        src={'/LST_Map4-website_2.webm'}
        preload={'auto'}
        onEnded={() => {setVisible(true)}}
      >
      </video>
      <div className="final" style={{display: visible ? 'flex' : 'none'}}>
        <a href="https://exchange.art/search?q=lost%20forgotten%20archives">&nbsp;&nbsp;EXCHANGE.ART&nbsp;</a>
      </div>
    </div>
  }

  return <VFlex>
    <Stack elements={[One, Two, Three, Four, Five]} active={active} onClick={active} />
  </VFlex>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" exact={true} element={<One />} />
          <Route path="/2" exact={true} element={<Two />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
