import { useLocation, Outlet } from "react-router-dom";

export function HFlex({children, hide, ...props}) {
  let { style, ...restProps } = props;
  if (props.grow)
    style = { ...style, flexGrow: 1 };
  return <div {...restProps} style={{display: hide ? 'none' : 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', ...style}}>{children}</div>
}

export function VFlex({children, ...props}) {
  let { style, ...restProps } = props;
  if (props.grow)
    style = { ...style, flexGrow: 1 };
  return <HFlex {...restProps} style={{flexDirection: 'column', ...style}}>{children}</HFlex>
}

export default function() {
  return <div className="outer-container">
    <div className="container">
      <Outlet />
    </div>
  </div>
    ;
}
